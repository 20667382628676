import React, { useMemo } from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import EmbeddedCta from "../components/Blog/EmbeddedCta";
import VideoLayout from "../containers/VideoLayout";
import Section from "../containers/Section";
import { graphql } from "gatsby";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../utils/breadcrumb";

// TODO: pass more info to match Figma Designs
const Video = ({ pageContext }) => {
  const { breadcrumb } = pageContext;

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.BlogIndex),
    []
  );

  return (
    <Layout hideNav>
      <SEO
        title={"eCommerce Advertising Blog"}
        description={
          "Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business."
        }
      />
      <div className="max-w-8xl mx-auto mt-48 px-6 md:px-4">
        <CustomBreadcrumb crumbs={updatedCrumbs} />
      </div>
      <VideoLayout />
      <Section mobileWide className="px-0 sm:px-4 sm:py-12">
        <EmbeddedCta
          fullWidth
          buttonText="Sign Up"
          title="Insights to help grow profit"
          textFieldPlaceholder="Email address"
          successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
        />
      </Section>
    </Layout>
  );
};

export default Video;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
