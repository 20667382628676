import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import AnchorNavigation from "../../components/Blog/AnchorNavigation";
import GradientSection from "../GradientSection";
import FeaturedVideoList from "../../components/Blog/FeaturedVideoList";
import VideoModal from "../../components/Blog/VideoModal";
import cn from "classnames";
import VideoList from "../../components/Blog/VideoList";
import VideoRecommendations from "../../components/Blog/VideoRecommendations";
import Section from "../Section";
import NavigationBar from "../../components/NavigationBar";
import { NavigationBarVariant } from "../../components/NavigationBar/models";

const navigation = [
  {
    link: "/video#customer-stories",
    text: "Customer Stories",
    textColor: "text-green-2-dark",
    textColorHover: "hover:text-green-2-dark"
  },
  {
    link: "/video#first-look",
    text: "First Look",
    textColor: "text-black",
    textColorHover: "hover:text-black"
  },
  {
    link: "/video#insights",
    text: "Insights",
    textColor: "text-indigo-1",
    textColorHover: "hover:text-indigo-1"
  },
  {
    link: "/video#growth-101",
    text: "Growth 101",
    textColor: "text-red-2",
    textColorHover: "hover:text-red-2"
  }
];

const VideoLayout = () => {
  const data = useStaticQuery(graphql`
    {
      firstlook: allYtFirstLook {
        nodes {
          snippet {
            description
            title
            thumbnails {
              high {
                url
              }
            }
          }
          id
        }
      }
      growth: allYtGrowth101 {
        nodes {
          snippet {
            description
            title
            thumbnails {
              high {
                url
              }
            }
          }
          id
        }
      }
      insights: allYtInsights {
        nodes {
          snippet {
            description
            title
            thumbnails {
              high {
                url
              }
            }
          }
          id
        }
      }
      customerstories: allYtCustomerStories {
        nodes {
          snippet {
            description
            title
            thumbnails {
              high {
                url
              }
            }
          }
          id
        }
      }
    }
  `);
  //Featured
  const featured = data?.firstlook?.nodes;

  // Article types to pass into the blog list variations
  const firstlook = data?.firstlook?.nodes;
  const customerstories = data?.customerstories?.nodes;
  const insights = data?.insights?.nodes;
  const growth = data?.growth?.nodes;

  const sections: any = [
    {
      title: "Customer Stories",
      anchor: "customer-stories",
      playlist: customerstories,
      color: "text-green-2-dark",
      button: "bg-green-2-dark",
      ctaPosition: 4,
      ctaSize: "medium",
      gradientSectionClasses: "pt-16 lg:pt-22  bg-gradient-to-b from-green-3",
      results: 5
    },
    {
      title: "First Look",
      anchor: "first-look",
      playlist: firstlook,
      color: "text-black",
      button: "bg-black",
      gradientSectionClasses: "pt-16 lg:pt-22  bg-gradient-to-b from-gray-7",
      results: 5
    },
    {
      title: "Insights",
      anchor: "insights",
      playlist: insights,
      color: "text-purple-2-dark",
      button: "bg-purple-2-dark",
      gradientSectionClasses: "pt-16 lg:pt-22  bg-gradient-to-b from-purple-3",
      results: 5
    },
    {
      title: "Growth 101",
      anchor: "growth-101",
      playlist: growth,
      color: "text-red-2-dark",
      button: "bg-red-2-dark",
      gradientSectionClasses: "pt-16 lg:pt-22  bg-gradient-to-b from-red-3",
      results: 5
    }
  ];

  const [elRefs, setElRefs] = React.useState([]);
  const [activeSection, setActiveSection] = React.useState<string>("");
  const [activeVideo, setActiveVideo] = React.useState<string>("");
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  function isVisible(ele, height) {
    const { top, bottom } = ele?.current?.getBoundingClientRect();
    return (top > 0 || bottom > 0) && top < height;
  }

  React.useEffect(() => {
    setElRefs(elRefs => sections?.map((_, i) => elRefs[i] || React.createRef()));
  }, [sections.length]);

  React.useEffect(() => {
    window?.addEventListener("scroll", () => {
      const currentScroll = window?.pageYOffset;
      elRefs?.map((item, i) => {
        if (item && item.current) {
          let divTop = item.current.offsetTop - item.current.offsetHeight / 3;
          let divBottom =
            item.current.offsetTop +
            item.current.offsetHeight +
            item.current.offsetHeight / 3;
          if (
            isVisible(item, window?.innerHeight) &&
            divTop < currentScroll &&
            currentScroll < divBottom
          ) {
            setActiveSection(item.current.id);
          }
        }
      });
    });
  }, [elRefs]);

  React.useEffect(() => {
    if (activeVideo !== "") {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [activeVideo]);

  return (
    <>
      <NavigationBar variant={NavigationBarVariant.blog} blogNav={navigation} />
      <GradientSection className="pt-6 lg:pt-8 md:pb-22 border-b-2 border-gray-5">
        <FeaturedVideoList
          publications={featured}
          title="Featured videos"
          color="text-gray-4-dark"
          setActiveVideo={setActiveVideo}
        />
      </GradientSection>
      <AnchorNavigation items={navigation} active={activeSection} />
      {sections?.map((section, i) => {
        return (
          <div key={i} id={section.anchor} ref={elRefs[i]}>
            <GradientSection
              className={cn(section.gradientSectionClasses, "min-h-[900px]")}
            >
              <VideoList
                title={section.title}
                ctaPosition={section.ctaPosition}
                CTA={section.cta}
                color={section.color}
                button={section.button}
                videos={section.playlist}
                results={section.results}
                setActiveVideo={setActiveVideo}
              />
            </GradientSection>
          </div>
        );
      })}
      <Section className="overflow-hidden">
        <VideoRecommendations
          setActiveVideo={setActiveVideo}
          videos={featured}
          title="Top videos"
        />
      </Section>
      {openModal && (
        <VideoModal src={activeVideo} setActiveVideo={setActiveVideo} />
      )}
    </>
  );
};

export default VideoLayout;
