import React from "react";
import ArrowRight from "../../../images/right-arrow.svg";
import { useState } from "react";
import VideoCard from "../VideoCard";

interface VideoRecommendationsProps {
  title?: string;
  videos?: any[];
  setActiveVideo?: any;
}

const VideoRecommendations: React.FC<VideoRecommendationsProps> = (
  props: any
) => {
  const { title, videos, setActiveVideo } = props;
  const [position, setPostion] = useState<number>(1);

  const getCurrentTranslate = () => {
    if (position === 1) {
      return `translateX(-0%)`;
    } else {
      return `translateX(-${(position - 1) * 100}%)`;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center pb-10">
        <p className="text-3xl md:text-4xl gray-dark-4 font-semibold">
          {title}
        </p>
        <div className="flex items-center gap-6 lg:hidden">
          <button
            className="focus:outline-none"
            onClick={() => {
              if (position === 1) {
                setPostion(3);
              } else {
                setPostion(position - 1);
              }
            }}
          >
            <ArrowRight className="text-indigo-1 rotate-180 relative top-[1px]" />
          </button>
          <button
            className="focus:outline-none"
            onClick={() => {
              if (position === 3) {
                setPostion(1);
              } else {
                setPostion(position + 1);
              }
            }}
          >
            <ArrowRight className="text-indigo-1" />
          </button>
        </div>
      </div>
      <div className="flex">
        {videos?.slice(0, 3).map((node, i) => {
          const publication = node?.snippet;
          const id = node?.id;
          return (
            <div
              className="transition transition-duration-200 ease-in pr-8 min-w-[400px] sm:min-w-[420px] lg:min-w-[auto] lg:w-1/3"
              style={{ transform: getCurrentTranslate() }}
            >
              <VideoCard
                id={id}
                setActive={setActiveVideo}
                publication={publication}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideoRecommendations;
