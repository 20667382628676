import React from "react";
import Header, { HeaderVariant } from "../../../components/Header";
import VideoFeatureCard from "../VideoFeatureCard";
import Loading from "../../Loading";

interface FeaturedVideoListProps {
  publications?: any;
  title?: string;
  CTA?: any | null;
  ctaPosition?: any;
  color?: string;
  ctaSize?: string;
  setActiveVideo?: any;
}

const FeaturedVideoList: React.FC<FeaturedVideoListProps> = props => {
  const { publications, title, color, setActiveVideo } = props;

  return (
    <>
      <Header className="md:mb-3 text-sm md:text-sm" variant={HeaderVariant.h1}>
        <span
          className={`font-medium text-2xl text-transparent mb-0 bg-gradient-to-r from-indigo-1 to-purple-4 bg-clip-text`}
        >
          Video
        </span>
      </Header>
      <Header variant={HeaderVariant.h2}>
        <span
          className={`${color} text-4xl flex mb-12 font-semibold lg:font-bold lg:mb-16 lg:text-5xl`}
        >
          {title}
        </span>
      </Header>
      {publications?.length > 0 ? (
        <VideoFeatureCard
          setActive={setActiveVideo}
          publication={publications[0].snippet}
          id={publications[0].id}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FeaturedVideoList;
